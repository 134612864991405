import React from "react";
import styled from "styled-components";
import ReactModal from "react-modal";

import {
  lightBlue,
  lightGray,
  darkGray,
  doubleSpace,
  halfSpace,
  singleSpace,
  Paragraph,
  PrimaryButton,
  SectionSubheader,
} from "components/sharedComponents";

export const MessageHistoryModal = ({ isOpen, messages, onClose }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={htmlModalStyle}
      shouldCloseOnOverlayClick={true}
    >
      <StyledModal>
        <div className="header">
          <div className="button-container">
            <button onClick={onClose}>X</button>
          </div>
          <SectionSubheader style={{ marginBottom: "45px" }}>
            Message History
          </SectionSubheader>
        </div>
        <div className="messages">
          <Messages messages={messages} />
        </div>
      </StyledModal>
    </ReactModal>
  );
};

const Messages = ({ messages }) => {
  return (
    <div>
      {messages.map((message, index) => (
        <div
          className={message.sender === "office" ? "office message" : "message"}
        >
          <Paragraph>{message.message}</Paragraph>
        </div>
      ))}
    </div>
  );
};

const htmlModalStyle = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: 0,
    backgroundColor: "rgba(0,0,0,0.1)",
    zIndex: 100,
  },
  content: {
    margin: "auto",
    width: "50%",
    maxWidth: "600px",
    height: "fit-content",
    background: "white",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "10px",
    outline: "none",
    padding: "15px",
    border: 0,
  },
};

const StyledModal = styled.div`
  .button-container {
    margin-bottom: ${singleSpace};
    text-align: right;

    button {
      display: inline;
      border: none;
      background: none;
      color: ${lightBlue};
    }
  }

  .message {
    p {
      display: inline-block;
      max-width: 80%;
      margin-bottom: ${doubleSpace};
      border: 1px solid ${lightGray};
      padding: ${singleSpace};
      border-radius: 5px;
    }

    &.office {
      text-align: right;

      p {
        background-color: ${lightBlue};
        border: none;
        text-align: left;
      }
    }
  }
`;
