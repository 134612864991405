import React, { useState } from "react";

import { CalloutDashboard } from "./components/CalloutDashboard";
import { Header } from "./components/Header";
import { ShiftList } from "./components/ShiftList";

const SCREENS = {
  calloutDashboard: "calloutDashboard",
  shiftList: "shiftList",
};

export const CaregiverStatus = (props) => {
  const [visibleScreen, setVisibleScreen] = useState(SCREENS.shiftList);

  const showShiftList = () => {
    setVisibleScreen(SCREENS.shiftList);
  };

  const showCalloutDashboard = () => {
    setVisibleScreen(SCREENS.calloutDashboard);
  };

  const title =
    visibleScreen === SCREENS.shiftList
      ? "Caregiver Status"
      : "Callout Dashboard";

  return (
    <div>
      <Header
        showCalloutDashboard={showCalloutDashboard}
        showShiftList={showShiftList}
        title={title}
        visibleScreen={visibleScreen}
      />
      {visibleScreen === SCREENS.shiftList ? (
        <ShiftList />
      ) : (
        <CalloutDashboard />
      )}
    </div>
  );
};
