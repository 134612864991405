import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  blue,
  doubleSpace,
  singleSpace,
  tripleSpace,
  SectionHeader,
} from "components/sharedComponents";

export const Header = ({
  showCalloutDashboard,
  showShiftList,
  title,
  visibleScreen,
}) => {
  return (
    <StyledHeader>
      <Container fluid>
        <Row>
          <Col xs={{ span: 10 }}>
            <SectionHeader>{title}</SectionHeader>
          </Col>
          <Col xs={{ span: 2 }}>
            <button
              className={visibleScreen === "shiftList" ? "selected" : ""}
              onClick={showShiftList}
            >
              Shift List
            </button>
            <button
              className={visibleScreen === "calloutDashboard" ? "selected" : ""}
              onClick={showCalloutDashboard}
            >
              Callouts
            </button>
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  );
};

const StyledHeader = styled.section`
  margin: ${tripleSpace} ${doubleSpace};

  button {
    background: none;
    border: 1px solid ${blue};
    padding: ${singleSpace};
    border-radius: 5px;
    margin-right: 10px;

    &:hover,
    &.selected {
      background-color: ${blue};
      color: white;
    }
  }
`;
