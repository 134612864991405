import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  blue,
  greenOpaque,
  lightGray,
  redOpaque,
  doubleSpace,
  mediumGray,
  quadSpace,
  Paragraph,
  PrimaryButton,
  singleSpace,
  Subheader,
  CardHeader,
  tripleSpace,
} from "components/sharedComponents";

import {
  CHECK_STATE,
  MESSAGE_CLASSIFICATION,
  SHIFTS,
  STAFFING_MANAGERS,
} from "screens/CaregiverStatus/data/mockData";

const STATE = ["Unresolved", "Resolved"];

const callouts = SHIFTS.filter((shift) => {
  const checkCancelled =
    shift.storedFirstCheck === CHECK_STATE.cancelled ||
    shift.storedSecondCheck === CHECK_STATE.cancelled ||
    shift.storedThirdCheck === CHECK_STATE.cancelled;

  const messageCallout =
    shift.caregiverMessages?.classification === MESSAGE_CLASSIFICATION.callout;

  return checkCancelled || messageCallout;
});

export const CalloutDashboard = () => {
  return (
    <StyledCommsList>
      <Container fluid>
        <Row className="header">
          <Col xs={{ span: 1 }}>
            <Paragraph>Date</Paragraph>
          </Col>
          <Col xs={{ span: 1 }}>
            <Paragraph>Time</Paragraph>
          </Col>
          <Col xs={{ span: 2 }}>
            <Paragraph>Staffing Manager</Paragraph>
          </Col>
          <Col xs={{ span: 2 }}>
            <Paragraph>Caregiver</Paragraph>
          </Col>
          <Col xs={{ span: 2 }}>
            <Paragraph>Patient</Paragraph>
          </Col>
          <Col xs={{ span: 2 }}>
            <Paragraph>Status</Paragraph>
          </Col>
          <Col xs={{ span: 2 }}>
            <Paragraph>Actions</Paragraph>
          </Col>
        </Row>
        {callouts.map((callout, i) => (
          <Callout key={i} {...callout} />
        ))}
      </Container>
    </StyledCommsList>
  );
};

const Callout = ({ date, caregiver, manager, patient, id, time }) => {
  const assignedIndex = STAFFING_MANAGERS.indexOf(manager);

  const [selectedAssignee, setSelectedAssignee] = useState(
    STAFFING_MANAGERS[assignedIndex],
  );
  const [itemState, setItemState] = useState(STATE[0]);

  const onChangeAssignee = (value) => {
    setSelectedAssignee(value);
  };

  const onChangeState = (event) => {
    console.log(event);
    setItemState(event.target.value);
  };

  return (
    <StyledCallout className={itemState === "Resolved" ? "resolved" : ""}>
      <Row>
        <Col xs={{ span: 1 }}>
          <Paragraph>{date}</Paragraph>
        </Col>
        <Col xs={{ span: 1 }}>
          <Paragraph>{time}</Paragraph>
        </Col>
        <Col xs={{ span: 2 }}>
          <AssigneeDropdown
            assigned={manager}
            onChangeAssignee={onChangeAssignee}
          />
        </Col>
        <Col xs={{ span: 2 }}>
          <Paragraph>{caregiver}</Paragraph>
        </Col>
        <Col xs={{ span: 2 }}>
          <Paragraph>{patient}</Paragraph>
        </Col>

        <Col xs={{ span: 2 }}>
          <StatusDropdown
            currentState={itemState}
            onChangeState={onChangeState}
          />
        </Col>
        <Col xs={{ span: 2 }}>
          <Paragraph>
            <a href={`/caregiver-callout/${id}`}>Fill Shift</a>
          </Paragraph>
        </Col>
      </Row>
    </StyledCallout>
  );
};

const AssigneeDropdown = ({ assigned, onChangeAssignee }) => {
  return (
    <StyledDropdown>
      <select
        id="assignee-select"
        value={assigned}
        onChange={(event) => onChangeAssignee(event.target.value)}
      >
        {STAFFING_MANAGERS.map((assignee, index) => (
          <option key={index} value={assignee}>
            {assignee}
          </option>
        ))}
      </select>
    </StyledDropdown>
  );
};

const StatusDropdown = ({ currentState, onChangeState }) => {
  console.log(currentState);
  return (
    <StyledDropdown>
      <select id="state-select" value={currentState} onChange={onChangeState}>
        {STATE.map((s, index) => (
          <option key={index} value={s}>
            {s}
          </option>
        ))}
      </select>
    </StyledDropdown>
  );
};

const StyledCommsList = styled.section`
  .header {
    padding: ${doubleSpace};
    p {
      font-weight: 600;
    }
  }
`;

const StyledCallout = styled.div`
  background-color: ${redOpaque};
  padding: ${quadSpace} ${doubleSpace};
  border-bottom: 1px solid ${lightGray};

  &.resolved {
    background-color: ${greenOpaque};
  }
`;

const StyledDropdown = styled.div`
  select {
    padding: 5px ${singleSpace};
    border-radius: 5px;
    border: 1px solid ${mediumGray};
  }
`;
