import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { AuthProvider } from "components/AuthContext";
import { HttpClientProvider } from "components/HttpClient";
import { MetaProvider } from "components/MetaContext";

import { Footer } from "components/Footer";
import { GlobalStyle } from "components/GlobalStyle";
import { Header } from "components/Header";
import { Logout } from "components/Logout";
import { PrivateRoute } from "components/PrivateRoute";
import { TransparentHeader } from "components/ScreenBase.js";
import { ScrollToTop } from "components/ScrollToTop.js";

import { AddProtectedAccount } from "screens/AddProtectedAccount/AddProtectedAccount";
import { AppointmentBooking } from "screens/AppointmentBooking/AppointmentBooking";
import { CaregiverCallout } from "screens/CaregiverCallout/CaregiverCallout";
import { CaregiverStatus } from "screens/CaregiverStatus/CaregiverStatus";
import { CleanInbox } from "screens/CleanInbox/CleanInbox";
import { CommsDashboard } from "screens/CommsDashboard/CommsDashboard";
import { Contact } from "screens/Contact/Contact";
import { CreateJob } from "screens/OrganizeInbox/OrganizeInbox";
import { CommsWorkflowBuilder } from "screens/CommsWorkflow/CommsWorkflowBuilder";
import { HomePage } from "screens/HomePage/HomePage";
import { Inboxes } from "screens/Inboxes/Inboxes";
import { InboxSurvey } from "screens/InboxSurvey/InboxSurvey";
import { JobSummary } from "screens/JobSummary/JobSummary";
import { LaunchPad } from "screens/LaunchPad/LaunchPad";
import { LoginLink } from "screens/Login/LoginLink";
import { Login } from "screens/Login/Login";
import { NotFound } from "screens/NotFound";
import { OurStory } from "screens/OurStory";
import { PrivacyPolicy } from "screens/PrivacyPolicy";
import { ProductPage } from "screens/ProductPage/ProductPage";
import { ProductPage2 } from "screens/ProductPage/ProductPage2";
import { ProductPage3 } from "screens/ProductPage/ProductPage3";
import { ProductPage4 } from "screens/ProductPage/ProductPage4";
import { Slack } from "screens/Inboxes/Slack";
import { Signup } from "screens/Signup/Signup";
import { TermsAndConditions } from "screens/TermsAndConditions";
import { UserSettings } from "screens/UserSettings/UserSettings";
import { Waitlist } from "screens/Waitlist/Waitlist";
import { WaitlistConfirmation } from "screens/WaitlistConfirmation/WaitlistConfirmation";

import { WithTracking } from "services/analytics";

function App() {
  const isProd = process.env.NODE_ENV === "production";

  return (
    <AuthProvider>
      <HttpClientProvider>
        <MetaProvider>
          <GlobalStyle />
          <Router>
            {isProd && <WithTracking />}
            <ScrollToTop />
            <Header theme={TransparentHeader} />
            <Routes>
              {/* PUBLIC ROUTES */}
              <Route path="/" exact element={<HomePage />} />
              <Route path="/about" element={<OurStory />} />
              <Route path="/book" element={<AppointmentBooking />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/inbox-survey" element={<InboxSurvey />} />
              <Route path="/ios-signup" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/link" element={<LoginLink />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/product" element={<ProductPage />} />
              <Route path="/product-2" element={<ProductPage2 />} />
              <Route path="/product-3" element={<ProductPage3 />} />
              <Route path="/product-4" element={<ProductPage4 />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/waitlist" element={<Waitlist />} />

              {/* PRIVATE ROUTES */}
              <Route
                path="/accept-invite/:id"
                element={<AddProtectedAccount />}
              />
              <Route
                path="/caregiver-callout/:shiftId"
                element={
                  <PrivateRoute>
                    <CaregiverCallout />
                  </PrivateRoute>
                }
              />
              <Route
                path="/caregiver-status"
                element={
                  <PrivateRoute>
                    <CaregiverStatus />
                  </PrivateRoute>
                }
              />
              <Route
                path="/clean-inbox"
                element={
                  <PrivateRoute>
                    <CleanInbox />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <CommsDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/comms-workflow"
                element={
                  <PrivateRoute>
                    <CommsWorkflowBuilder />
                  </PrivateRoute>
                }
              />
              <Route
                path="/inboxes"
                element={
                  <PrivateRoute>
                    <Inboxes />
                  </PrivateRoute>
                }
              />
              <Route
                path="/launch/:workflowId"
                element={
                  <PrivateRoute>
                    <LaunchPad />
                  </PrivateRoute>
                }
              />
              <Route
                path="/organize-inbox"
                element={
                  <PrivateRoute>
                    <CreateJob />
                  </PrivateRoute>
                }
              />
              <Route
                path="/organize-inbox/:jobId/:actionId?/:dest?"
                element={
                  <PrivateRoute>
                    <JobSummary type={"organize"} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <UserSettings />
                  </PrivateRoute>
                }
              />
              <Route
                path="/slack"
                element={
                  <PrivateRoute>
                    <Slack />
                  </PrivateRoute>
                }
              />
              <Route
                path="/summary/:jobId/:actionId?/:dest?"
                element={
                  <PrivateRoute>
                    <JobSummary type={"summary"} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/product" element={<ProductPage />} />
              <Route path="/product-2" element={<ProductPage2 />} />
              <Route path="/product-3" element={<ProductPage3 />} />
              <Route path="/product-4" element={<ProductPage4 />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/waitlist" element={<Waitlist />} />
              <Route
                path="/waitlist-confirm"
                element={<WaitlistConfirmation />}
              />

              {/* 404 */}
              <Route path="/*" element={<NotFound />} />
            </Routes>
            <Footer />
          </Router>
        </MetaProvider>
      </HttpClientProvider>
    </AuthProvider>
  );
}

export default App;
