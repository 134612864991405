import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  blue,
  green,
  lightGray,
  red,
  orange,
  doubleSpace,
  mediumGray,
  quadSpace,
  Paragraph,
  PrimaryButton,
  singleSpace,
  Subheader,
  CardHeader,
  tripleSpace,
} from "components/sharedComponents";

import {
  CHECK_STATE,
  CAREGIVERS,
  MESSAGE_CLASSIFICATION,
  PATIENTS,
  SHIFTS,
  SHIFT_STATE,
} from "screens/CaregiverStatus/data/mockData";

import { MessageHistoryModal } from "screens/CaregiverStatus/components/MessageHistoryModal";

export const ShiftList = () => {
  return (
    <StyledShiftList>
      <Container fluid>
        <Row className="header">
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Status</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Caregiver</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Patient</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Shift Date</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Shift Time</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Accepted shift</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>72-hour check</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>8-hour check</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Post-shift check</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Caregiver Messages</Paragraph>
            </StyledColumn>
          </Col>
        </Row>
        {SHIFTS.map((item, i) => (
          <Shift key={i} {...item} />
        ))}
      </Container>
    </StyledShiftList>
  );
};

const firstMessage = ({ caregiver, date, patient, time, checkStatus }) => {
  const caregiverFirstName = caregiver.split(" ")[0];
  const caregiverResponse =
    checkStatus === CHECK_STATE.cancelled
      ? `Sorry I had something come up I can't make it anymore`
      : `Yes that works`;

  const officeResponse =
    checkStatus === CHECK_STATE.cancelled
      ? `Got it thanks for letting me know`
      : `Great thank you! You can text me if anything changes.`;

  return [
    {
      message: `Hi ${caregiverFirstName}, this is Sophia with the staffing team at Olympic Homecare. We just scheduled you for a shift with ${patient} on ${date} at ${time}. Confirming that works for you?`,
      sender: "office",
    },
    {
      message: caregiverResponse,
      sender: "caregiver",
    },
    {
      message: officeResponse,
      sender: "office",
    },
  ];
};

const secondMessage = ({ caregiver, date, patient, time, checkStatus }) => {
  const caregiverFirstName = caregiver.split(" ")[0];
  const caregiverResponse =
    checkStatus === CHECK_STATE.cancelled
      ? `Sorry I had something come up I can't make it anymore`
      : `Yes that works`;

  const officeResponse =
    checkStatus === CHECK_STATE.cancelled
      ? `Got it thanks for letting me know`
      : `Great thank you! You can text me if anything changes.`;

  return [
    {
      message: `Hi ${caregiverFirstName}, this is Sophia with the staffing team at Olympic Homecare. Hope you're doing well. I see you have a shift coming up on ${date} at ${time}. Confirming you can still make it?`,
      sender: "office",
    },
    {
      message: caregiverResponse,
      sender: "caregiver",
    },
    {
      message: officeResponse,
      sender: "office",
    },
  ];
};

const thirdMessage = ({ caregiver, date, patient, time, checkStatus }) => {
  const caregiverFirstName = caregiver.split(" ")[0];
  if (checkStatus === CHECK_STATE.cancelled) {
    return [
      {
        message: `Hi ${caregiverFirstName}, it's Sophia at Olympic Homecare. Confirming you have everything you need for your shif tomorrow at ${time}?`,
        sender: "office",
      },
      {
        message: `Sorry my kid got sick today, I can't make it anymore.`,
        sender: "caregiver",
      },
      {
        message: `Sorry to hear it! Thanks for letting me know.`,
        sender: "office",
      },
    ];
  }

  return [
    {
      message: `Hi ${caregiverFirstName}, it's Sophia at Olympic Homecare. Confirming you have everything you need for your shif tomorrow at ${time}?`,
      sender: "office",
    },
    {
      message: `Can you confirm the address for me?`,
      sender: "caregiver",
    },
    {
      message: `For sure the address is 419 Parker Ave, Berkeley, CA.`,
      sender: "office",
    },
    {
      message: `Got it thank you`,
      sender: "caregiver",
    },
    {
      message: `No problem! So you can still make the shift?`,
      sender: "office",
    },
    {
      message: `Yes I'll be there.`,
      sender: "caregiver",
    },
  ];
};

const checkOutMessages = ({ caregiver, date, patient, time }) => {
  const caregiverFirstName = caregiver.split(" ")[0];
  return [
    {
      message: `Hi ${caregiverFirstName}! Just checking in on how your shift with ${patient} went today. If you had any issues or concerns with the shift please let me know.`,
      sender: "office",
    },
    {
      message: `Hi Sophia thanks for checking in. Shift was good. ${
        patient.split(" ")[0]
      } said she's been feeling lonely and asked if we can stay longer next time.`,
      sender: "caregiver",
    },
    {
      message: `Got it thanks for letting me know. I'll talk to the team to see what we can do about that.`,
      sender: "office",
    },
    {
      message: `Great thank you`,
      sender: "caregiver",
    },
  ];
};

const Shift = ({
  caregiver,
  caregiverMessages,
  checkOut,
  date,
  patient,
  time,
  storedFirstCheck,
  storedSecondCheck,
  storedThirdCheck,
}) => {
  const [firstCheck, setFirstCheck] = useState(storedFirstCheck);
  const [secondCheck, setSecondCheck] = useState(storedSecondCheck);
  const [thirdCheck, setThirdCheck] = useState(storedThirdCheck);

  const status = (() => {
    const toCheck = [firstCheck, secondCheck, thirdCheck];
    if (caregiverMessages.classification === MESSAGE_CLASSIFICATION.callout) {
      return SHIFT_STATE.cancelled;
    }

    if (toCheck.includes(CHECK_STATE.cancelled)) {
      return SHIFT_STATE.cancelled;
    }

    if (toCheck.includes(CHECK_STATE.missed)) {
      return SHIFT_STATE.atRisk;
    }

    return SHIFT_STATE.ok;
  })();

  const checkClassName = (toCheck) => {
    if (toCheck === CHECK_STATE.cancelled) {
      return "cancelled";
    }

    if (toCheck === CHECK_STATE.missed) {
      return "at-risk";
    }

    if (toCheck === CHECK_STATE.notSent) {
      return "not-sent";
    }

    return "ok";
  };

  const onChangeFirstCheck = (value) => {
    setFirstCheck(value);
  };

  const onChangeSecondCheck = (value) => {
    setSecondCheck(value);
  };

  const onChangeThirdCheck = (value) => {
    setThirdCheck(value);
  };

  return (
    <StyledShiftItem>
      <Row>
        <Col xs={{ span: 1 }}>
          <StyledColumn
            className={
              status === SHIFT_STATE.ok
                ? "ok"
                : status === SHIFT_STATE.atRisk
                  ? "at-risk"
                  : "cancelled"
            }
          >
            <Paragraph>{status}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{caregiver}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{patient}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <Paragraph>{date}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <Paragraph>{time}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn className={checkClassName(firstCheck)}>
            <CheckInState
              messages={firstMessage({
                caregiver,
                date,
                patient,
                time,
                checkStatus: firstCheck,
              })}
              state={firstCheck}
              onChange={onChangeFirstCheck}
            />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn className={checkClassName(secondCheck)}>
            <CheckInState
              messages={secondMessage({
                caregiver,
                date,
                patient,
                time,
                checkStatus: secondCheck,
              })}
              state={secondCheck}
              onChange={onChangeSecondCheck}
            />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn className={checkClassName(thirdCheck)}>
            <CheckInState
              messages={thirdMessage({
                caregiver,
                date,
                patient,
                time,
                checkStatus: thirdCheck,
              })}
              state={thirdCheck}
              onChange={onChangeThirdCheck}
            />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <CheckOutState
              date={date}
              messages={checkOutMessages({ caregiver, date, patient, time })}
              time={time}
            />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <CaregiverMessages messages={caregiverMessages} />
          </StyledColumn>
        </Col>
      </Row>
    </StyledShiftItem>
  );
};

const CheckInState = ({ messages, onChange, state }) => {
  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(false);
  };
  return (
    <div>
      <StyledDropdown>
        <select
          id="check-state"
          value={state}
          onChange={(event) => onChange(event.target.value)}
        >
          {Object.entries(CHECK_STATE).map(([key, value]) => (
            <option key={key} value={value}>
              {value}
            </option>
          ))}
        </select>
      </StyledDropdown>
      <button onClick={() => setModal(true)}>View</button>
      <MessageHistoryModal
        isOpen={modal}
        messages={messages}
        onClose={closeModal}
      />
    </div>
  );
};

const CaregiverMessages = ({ messages }) => {
  const [modal, setModal] = useState(false);
  const label =
    messages.classification === MESSAGE_CLASSIFICATION.callout ? "Alert" : "OK";

  return (
    <div>
      <Paragraph
        className={
          messages.classification === MESSAGE_CLASSIFICATION.callout
            ? "alert"
            : ""
        }
      >
        {label}
      </Paragraph>
      <button onClick={() => setModal(true)}>View</button>
      <MessageHistoryModal
        isOpen={modal}
        messages={messages.messages}
        onClose={() => setModal(false)}
      />
    </div>
  );
};

const generateTimestamp = ({ date, time }) => {
  const [month, day] = date.split("/").map(Number);
  const [hourMinute, meridiem] = time.split(" ");
  let [hour, minute] = hourMinute.split(":").map(Number);

  if (meridiem === "PM" && hour !== 12) {
    hour += 12;
  } else if (meridiem === "AM" && hour === 12) {
    hour = 0;
  }

  const currentYear = new Date().getFullYear();

  const timestamp = new Date(currentYear, month - 1, day, hour, minute);

  return timestamp;
};

const CheckOutState = ({ date, messages, time }) => {
  const [modal, setModal] = useState(false);
  const scheduled = generateTimestamp({ date, time });
  const scheduledPlusOneHour = new Date(scheduled.getTime() + 60 * 60 * 1000);
  const now = new Date();

  if (now < scheduledPlusOneHour) {
    return <div></div>;
  }

  return (
    <div>
      <Paragraph>Sent</Paragraph>
      <button onClick={() => setModal(true)}>View</button>
      <MessageHistoryModal
        isOpen={modal}
        messages={messages}
        onClose={() => setModal(false)}
      />
    </div>
  );
};

const StyledShiftList = styled.section`
  .header {
    padding: ${doubleSpace};
    p {
      font-weight: 600;
    }
  }
`;

const StyledColumn = styled.div`
  padding-top: ${doubleSpace};
  padding-bottom: ${doubleSpace};
  text-align: center;

  p.alert {
    color: ${red};
  }

  &.cancelled {
    p,
    select {
      color: ${red};
      font-weight: bold;
    }
  }

  &.ok {
    p,
    select {
      color: ${green};
    }
  }

  &.at-risk {
    p,
    select {
      color: ${orange};
    }
  }

  &.not-sent {
    background-color: none;
  }

  button {
    border: none;
    background: none;
    display: inline-block;
    margin-top: ${singleSpace};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledShiftItem = styled.div`
  border-bottom: 1px solid ${lightGray};
`;

const StyledDropdown = styled.div`
  select {
    background: none;
    padding: 5px;
    border-radius: 5px;
    border: none;
    max-width: 100%;
    font-weight: 600;
  }
`;
