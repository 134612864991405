import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  ActionButton,
  ErrorMessage,
  Paragraph,
  Subheader,
  SectionSubheader,
  lightBlue,
  lightGray,
  gray,
  md,
  singleSpace,
  doubleSpace,
  tripleSpace,
  quadSpace,
} from "components/sharedComponents";
import { HttpClientContext } from "components/HttpClient";
import { ExecutedActions } from "./components/ExecutedActions";
import { SelectedActions } from "./components/SelectedActions";
import { CAREGIVERS, SHIFTS } from "screens/CaregiverStatus/data/mockData";

const ACTIONS = [
  {
    state: "unstarted",
    text: "Text available caregivers in the area with matching skills, asking if they can take the shift",
  },
  {
    state: "unstarted",
    text: "As soon as one accepts, notify the others the shift has been filled",
  },
  { state: "unstarted", text: "Send the new caregiver details of the shift" },
  {
    state: "unstarted",
    text: "Notify you which caregiver is taking the shift",
  },
  {
    state: "unstarted",
    text: "Document all the changes to the shift in ClearCare",
  },
];

export const CaregiverCallout = () => {
  const [actions, setActions] = useState(ACTIONS);
  const [error, setError] = useState(null);
  const [executing, setExecuting] = useState(false);
  const { get, post } = useContext(HttpClientContext);
  const { shiftId } = useParams();

  const shift = SHIFTS.find((s) => s.id === parseInt(shiftId));

  const execute = async () => {
    setExecuting(true);
    setActions((prevActions) => {
      const newActions = [...prevActions];
      newActions[0] = {
        state: "started",
        text: prevActions[0].text,
      };
      return newActions;
    });
  };

  return (
    <StyledLaunchPad>
      <Container>
        <Row>
          <Col lg={{ offset: 2, span: 8 }}>
            <Subheader>Fill Shift</Subheader>
            <Paragraph>
              {shift.caregiver} can't make her shift with {shift.patient} at{" "}
              {shift.time}
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col lg={{ offset: 2, span: 8 }}>
            <StyledPlanSection>
              <SectionSubheader>
                Here's what I'm planning to do:
              </SectionSubheader>
              <Paragraph>
                <ul>
                  {actions.map((action, index) => (
                    <li className={action.state === "started" ? "started" : ""}>
                      {action.text}
                      {action.state === "started" ? " [In Progress]" : ""}
                    </li>
                  ))}
                </ul>
              </Paragraph>
              <ActionButton
                disabled={executing}
                onClick={execute}
                text={executing ? "Executing..." : "Looks good, execute"}
              />
              <a href="" className="modify">
                Modify workflow
              </a>
            </StyledPlanSection>
          </Col>
        </Row>
        <Row>
          <Col lg={{ offset: 2, span: 8 }}>
            <StyledPlanSection>
              <SectionSubheader>
                Caregivers I will contact, in order:
              </SectionSubheader>
              <CaregiverDropdowns executing={executing} />
            </StyledPlanSection>
          </Col>
        </Row>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Container>
    </StyledLaunchPad>
  );
};

const CaregiverDropdowns = ({ executing }) => {
  const [selectedCaregivers, setSelectedCaregivers] = useState(
    CAREGIVERS.slice(0, 10),
  );

  const handleChange = (index, event) => {
    const updatedCaregivers = [...selectedCaregivers];
    updatedCaregivers[index] = event.target.value;
    setSelectedCaregivers(updatedCaregivers);
  };

  return (
    <div>
      {selectedCaregivers.map((selectedCaregiver, index) => {
        if (executing && index < 3) {
          return (
            <div key={index}>
              <span className="started">
                {index + 1} {": "}
                {selectedCaregiver} contacted
              </span>
            </div>
          );
        }
        return (
          <div key={index}>
            <label style={{ marginRight: "10px" }}>{index + 1}: </label>
            <select
              value={selectedCaregiver}
              onChange={(e) => handleChange(index, e)}
            >
              {CAREGIVERS.map((caregiver, index) => (
                <option key={index} value={caregiver}>
                  {caregiver}
                </option>
              ))}
            </select>
          </div>
        );
      })}
    </div>
  );
};

export default CaregiverDropdowns;

const StyledLaunchPad = styled.div`
  padding: ${quadSpace} 0 ${doubleSpace};

  p.started,
  li.started,
  span.started {
    color: ${lightGray};
  }

  select,
  span {
    display: inline-block;
    margin-bottom: ${singleSpace};
  }

  button {
    width: 200px;
    padding: ${singleSpace};
  }

  .row {
    margin-bottom: ${doubleSpace};
  }

  .modify {
    margin-left: ${singleSpace};
  }

  a {
    text-decoration: none;
  }

  h4 {
    margin-bottom: ${doubleSpace};
  }

  ul,
  ol {
    margin-bottom: ${doubleSpace};
  }

  p,
  li {
    margin-bottom: ${singleSpace};
  }
`;

const StyledPlanSection = styled.div`
  border: 1px solid ${lightGray};
  padding: ${quadSpace} ${doubleSpace};
  border-radius: 5px;
`;
