import React, { useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  doubleSpace,
  quadSpace,
  md,
  singleSpace,
  SectionSubheader,
} from "components/sharedComponents";

import { AuthContext } from "components/AuthContext";

import { EmailCapture } from "components/EmailCapture";
import { Hero } from "./components/Hero";
import { ProblemStatement } from "./components/ProblemStatement";
import { Result } from "./components/Result";
import { Solution } from "./components/Solution";
import { Testimonials } from "./components/Testimonials";

export const HomePage = (props) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/inboxes");
  }, [navigate, user]);

  return (
    <StyledHomePage>
      <Container>
        <Row style={{ justifyContent: "center" }}>
          <SectionSubheader style={{ marginBottom: "45px" }}>
            Sanctum is currently in private beta. Sign up for the waitlist
            below.
          </SectionSubheader>
          <EmailCapture
            headline={"Join the Waitlist"}
            category="inbox-demo-0.1"
          />
        </Row>
      </Container>
    </StyledHomePage>
  );

  return (
    <StyledHomePage>
      <Hero />
      <ProblemStatement />
      <Solution />
      <Testimonials />
      <Result />
      <EmailCapture headline={"Join the Waitlist"} category="inbox-demo-0.1" />
    </StyledHomePage>
  );
};

const StyledHomePage = styled.div`
  padding: ${quadSpace} 0;
`;
